<template>
  <div>
    <Overlay :overlay="overlay" />
    <v-app-bar app clipped-left color="white" elevation="1">
      <v-card class="elevation-0" max-width="170">
        <v-img src="@/assets/SureCredsLogo.png"></v-img>
      </v-card>
      <v-spacer></v-spacer>

      <span>
        <div class="PrimaryFontColorSmall">
          {{ $store.getters.get_user_email }}
        </div>
        <div class="PrimaryFontColorXSmall" align="center">
          {{
            user_type.includes("_") == true
              ? user_type.replace("_", " ")
              : user_type
          }}
        </div>
      </span>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            @click="clearData()"
            v-on="on"
            v-bind="attrs"
            color="secondary"
          >
            <v-icon>mdi-exit-to-app</v-icon>
          </v-btn>
        </template>
        <span class="font-weight-bold">Logout ?</span>
      </v-tooltip>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      app
      clipped
      width="190px"
      color="secondary"
      dark
    >
      <v-list dark dense>
        <v-list-group
          link
          v-for="(item, idx) in RouterList"
          :key="idx"
          :append-icon="item.has_child_routes == true ? 'mdi-chevron-down' : ''"
          no-action
          v-on:click="setTimeOutMethod()"
          @click="
            $route.name != item.MenuRoute && item.has_child_routes == false
              ? $router.push(item.MenuRoute)
              : ''
          "
        >
          <template v-slot:activator>
            <v-list-item-action class="mr-0">
              <v-icon small color="white">{{ item.MenuICon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content class="px-0 mx-0" :to="item.MenuRoute" link>
              <v-list-item-title class="white--text">{{
                item.MenuName
              }}</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item-group link>
            <v-list-item
              v-for="(sitem, i) in item.SubMenu"
              :key="i"
              link
              :to="sitem.MenuRoute"
            >
              <v-icon color="white" class="mx-2" small>{{
                sitem.MenuICon
              }}</v-icon>
              <v-list-item-title class="white--text">{{
                sitem.MenuName
              }}</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <router-view :routeLoadToggle="routeLoadToggle" />
    </v-main>
  </div>
</template>
<script>
import Overlay from "@/components/Extras/Overlay.vue";
import { GetCurrentUser } from "@/mixins/GetCurrentUser.js";
// import { RLGetcurrentUser } from "../../mixins/RLGetCurrentUser.js";
export default {
  mixins: [GetCurrentUser],
  // mixins: [RLGetcurrentUser],

  components: {
    Overlay,
  },
  data: () => ({
    drawer: true,
    overlay: false,
    RouterList: [],
    routeLoadToggle: false,
  }),
  mounted() {
    this.GetCurrentUser();
    // this.RLGetCurrentUser();
  },
  methods: {
    setTimeOutMethod() {
      setTimeout(() => {
        this.routeLoadToggle = !this.routeLoadToggle;
        this.parent_called = false;
      }, 100);
    },
    clearData() {
      localStorage.clear();
      this.$router.push("/");
    },
  },
};
</script>
