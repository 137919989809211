import RouterListSuperAdmin from "@/JsonFiles/RouterListSuperAdmin.json";
import RouterListAdmin from "@/JsonFiles/RouterListAdmin.json";
import { GetCurrentUserDetails } from "@/graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
export const GetCurrentUser = {
  data() {
    return {
      getCurrentUserDetailsObject: {},
      overlay: false,
      RouterList: [],
      user_type: "",
    };
  },
  methods: {
    async GetCurrentUser() {
      this.overlay = true;
      let result = await API.graphql(
        graphqlOperation(GetCurrentUserDetails, {
          input: {
            user_email_id: this.$store.getters.get_user_email,
          },
        })
      );
      this.$store.commit(
        "SET_CURRENTUSER_DETAILS",
        JSON.parse(result.data.GetCurrentUserDetails).data.items[0]
      );
      this.user_type = JSON.parse(
        result.data.GetCurrentUserDetails
      ).data.items[0].user_type;
      if (this.$route.name == "LandingPage") {
        this.$router.push("/Issuer");
      }
      if (this.user_type == "SUPER_ADMIN") {
        this.RouterList = RouterListSuperAdmin;
      } else if (this.user_type == "ADMIN") {
        this.RouterList = RouterListAdmin;
      }
      this.overlay = false;
    },
  },
};
